<template>
    <div class="container usertermsCondtionMainDiv">
        <div class="userTermConditionHeading">
            <h2>RxIx End User Terms and Conditions </h2>
        </div>
        <p class="mt-4">
            This is a legally binding contract (hereinafter referred to as "Agreement") by and between:
        </p>
        <p class="py-1">
            End User(s) (hereinafter referred as "End User" or "End Users" or "You", which expression shall, unless it be
            repugnant to the context or meaning thereof, be deemed to mean and include his/her/their heirs, executors,
            administrators and legal representatives or its successors, as the case may be), i.e. any general public person,
            who accesses and/or uses the internet resource rxix.in, including, without limitation, all its associated
            sub-domains, and/or any accompanying or associated data, applications, utilities or interface available or
            provided now or in future (collectively referred to as "Site") or avails any End User Services (defined
            hereinafter) provided by Bamboo Wellness Solutions LLP, a Limited Liability Partnership registered under the
            Limited Liability Partnership Act, 2008, which expression shall unless it be repugnant to the context or meaning
            thereof, be deemed to mean and include, its successors and assigns, whose registered office is at FG, Block 2,
            Jains Ashraya Phase 1, 1A Vembuliamman Koil Street, West KK Nagar, Chennai - 600078 and Head office is at NSIC
            STP Complex, No. 403 & 404, 3rd Floor Business Park, B-24, Guindy Industrial Estate, Ekkatuthangal, Chennai,
            Tamil Nadu 600032
        </p>
        <p class="py-1">
            AND
        </p>
        <p class="py-1">
            Bamboo Wellness Solutions LLP (hereinafter referred to as the "Company" or "Us" or "We").
        </p>
        <p class="py-1">
            and each of which are hereinafter referred to as a "Party" and both of which are herein referred to as the
            "Parties".
        </p>
        <p class="py-1">
            Please read the terms and conditions carefully before using or accessing the Site or any material or information
            therein, or availing HCP Services (defined hereinafter) from the Company offered through the Site.
        </p>
        <p class="py-1">
            This Agreement is an electronic record in terms of the Information Technology Act, 2000 and the rules framed
            thereunder, as applicable. This electronic record is generated by a computer system and does not require any
            physical or digital signature. The agreement between, and the conduct of, the Parties shall be exclusively and
            wholly governed by this Agreement.
        </p>
        <p class="py-1">
            This Agreement is published in accordance with the provisions of Rule 3 (1) of the Information Technology
            (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and
            terms
            of use for access or usage of the Site.
        </p>
        <div>
            <p>1. <span class="UserSubHeading">INTERPRETATION</span></p>
            <p class="py-2">
                1.1. The words "hereof", "hereto", "herein", and "hereunder" and words of similar import, when used in this
                Agreement, shall refer to this Agreement as a whole, and not to any particular provision of this Agreement.
            </p>
            <p class="py-2">
                1.2. Words denoting the singular shall include the plural, and words denoting any gender shall include all
                genders.
            </p>
            <p>
                1.3. The headings to clauses, sub-clauses and paragraphs of this Agreement shall only serve the purpose of
                easier orientation and shall not affect the contents and interpretation of this Agreement.
            </p>
            <p class="py-2">
                1.4. Any reference to "writing" shall include printing, typing, lithography, an electronic record in terms
                of the Information Technology Act, 2000 and the rules framed thereunder as applicable, and other means of
                reproducing words in a visible form.
            </p>
            <p class="py-2">
                1.5. TThe words "include" and "including" are to be construed without limitation.
            </p>
            <p class="py-2">
                1.6. "Law" means all statutes, enactments, acts of legislature, laws, ordinances, rules, by-laws,
                regulations, negotiations, guidelines, policies, directions, directives and orders of any Authorized
                Authority of or within the Republic of India.
            </p>
            <p class="py-2">
                1.7. The words "Your" or "your" or "You" or "you", when used in this Agreement, shall refer to and mean "End
                User" or "End Users", as the case may be.
            </p>
            <p>2. <span class="HcpSubHeading">DEFINITIONS</span></p>
            <p class="py-2">
                The following words and terms, whenever used in this Agreement, unless repugnant to the meaning or context
                thereof, shall have the respective meanings set forth below.
            </p>
            <p class="py-2">
                2.1. "Applicable Laws" or "Law" shall mean any applicable national, state or local laws (both common law and
                statute law and civil and criminal law) and all applicable subordinate legislations and regulatory codes of
                practice (including statutory instruments, guidance notes, circulars, directives, decisions, regulations,
                treaties, conventions, ordinances, order of any government agency, requirement or recommendation of any
                statute, statutory instrument, by-law or any public, governmental or statutory authority or person); all
                being of the Republic of India.
            </p>
            <p class="py-2">
                2.2. "Company" or "Us" or "We", shall mean and include Bamboo Wellness Solutions LLP, its Offices, Officers,
                Directors, Partners, Owners, Administrators, independent Contractors, Employees, Agents, or affiliates, and
                its/their successors and assigns.
            </p>
            <p class="py-2">
                2.3. "Intellectual Property Rights", which may also be referred as IPR, shall mean and include all
                registered and unregistered trademarks, copyright in all forms, including but not limited to the contents of
                the Site, images, text, illustrations, audio clips, trademarks, logos, labels, video clips, software and
                coding; industrial designs, patents, inventions, domain names, trade secrets, methodology, processes,
                features, functionality, End User Information and common law rights in the aforesaid, which are associated
                with the Company, Services or the System.
            </p>
            <p class="py-2">
                2.4."Person" shall mean a person, and includes any individual, corporation, firm, partnership, joint
                venture, association, organisation, trust, state or Governmental Authority or other legal entity (in each
                case, whether having separate legal personality or not).
            </p>
            <p class="py-2">
                2.5. "HCP" or "HCPs" shall mean and include commonly identified healthcare professional(s), such as doctors,
                physiotherapists, and such other medical or paramedical professional(s) registered on the Site.
            </p>
            <p class="py-2">
                2.6. “Services” shall mean “End User Services”.
            </p>
            <p class="py-2">
                2.7. "End User Services" shall mean End User Services, provided by the Company through its Site and System.
                More details of the End User Services are available in the section titled, End User Services.
            </p>
            <p class="py-2">
                2.8. "Site" shall mean and include, in whole or in part, the internet resource and website known as rxix.in,
                including but not limited to all its associated sub-domains, any accompanying or associated data,
                applications, utilities or interface.
            </p>
            <p class="py-2">
                2.9. "System" shall mean and include the Site, electronic communication network, including all software, all
                hardware, all files and images and data contained in or generated by the System associated with the Site.
            </p>
            <p class="py-2">
                2.10. "Use" shall mean and include any use of the Site or Services by a Person, including, without
                limitation, Registered End Users and any other Person who accesses or visits the Site and their relative(s),
                representatives, agents, servants or affiliates, for whom, or on whose behalf, such End User, Registered End
                User, or Person is using the Site and availing the Services.
            </p>
            <p class="py-2">
                2.11. "End User" and "End Users" shall mean and include a Registered End User or any Person who Uses the
                Site, End Users, and his relative(s), representatives, agents, servants or affiliates, for whom or on whose
                behalf, the End User is using the Site. For the purposes of this document, End User excludes HCPs registered
                on the Site.
            </p>
            <p class="py-2">
                2.12. "End User Account Information" shall mean and include the name, email address, mobile number,
                password, date of birth, gender, city, pincode and any other information as may be required by the Company
                in relation to the End User Account.
            </p>
            <p class="py-2">
                2.13. "End User Information" shall mean and include End User Account Information, End User-generated
                content, HCP(s)-generated content, medical records; information generated, directly or indirectly, subject
                to the privacy policy of the Company, with respect to the End User Account, during the process of obtaining
                End User Services by the End User, or otherwise.
            </p>
            <p>3. <span class="HcpSubHeading">ACCEPTANCE OF TERMS</span></p>
            <p class="py-2">
                3.1. This Agreement applies to the Company and the End User and governs the provision of access to End Users
                of the Site, and/or the use by End Users of any End User Services provided on or through the Site.
            </p>
            <p class="py-2">
                3.2. By enabling the checkbox "I agree to RxIx Terms and Conditions" at the time of registration or by the
                mere use of the End User Services provided by the Company through the Site by the End User, the End User
                shall be and be deemed to be bound by this Agreement, and to have read, understood and unconditionally
                accepted this Agreement in its entirety, concerning its access of the Site and the use of End User Services
                provided on or through the Site.
            </p>
            <p class="py-2">
                3.3. By using the Site or accessing any material, information or services through the Site, the End User
                agrees, admits, confirms and declares that the End User has completed 18 years of age as on date of this
                Agreement, and that the End User has fully read and understood the Terms and Conditions (also referred as
                "End User Terms of Use"), as set forth in this Agreement, without any impairment in judgment resulting from
                (but not limited to) mental illness, mental handicap, intoxication, medication, or any other health or other
                problem that could impair judgment.
            </p>
            <p class="py-2">
                3.4. In case the End User has any issue or difficulty with the understanding of this Agreement or any of its
                clause or portion, you are advised not to Use the Site and if the End User uses the Site, it shall be
                presumed and signified that the End User has agreed to be bound by the terms and conditions of the Agreement
                without any objection or reservation.
            </p>
            <p class="py-2">
                3.5. The Company may amend any policies or supplemental terms (including the Community Guidelines) related
                to the Services from time to time. The Company will provide you with at least 30 days' written notice in the
                event of a material change to any policies or supplemental terms that detrimentally affects your rights
                under these Terms. Amendments will be effective upon the Company’s posting of such amended policies or
                supplemental terms on the applicable Service. Your continued access or use of the Services after such
                posting, or after the expiry of the notice period (whichever is later), constitutes your consent to be bound
                by the Terms, as amended.
            </p>
            <p class="py-2">
                3.6. By the mere use of or accessing the Site, you shall be contracting with the Company, and these terms
                and conditions, including the privacy policy, constitute your binding obligation.
            </p>
            <p class="py-2">
                3.7. Further, by the mere use of or accessing the Site, you agree and declare that you are intending to use
                the End User Services offered by the Company through the Site on your own volition, free will, without any
                undue influence, force or coercion, while in sound mind and disposing capacity, and your being legally
                capable of contracting in law.
            </p>
            <p class="py-2">
                3.8. The Company is, and the End User accepts that the Company is, the owner, author and publisher of the
                Site and the operator of the System associated with the Site for providing the Services.
            </p>
            <p class="py-2">
                3.9. The Site is not endorsing and shall never be deemed to endorse any particular HCP and nothing shall be
                construed as endorsement by this Site, and/or any person, entity or advice, including the results of any
                search you perform on the Site for HCPs.
            </p>
            <p class="py-2">
                3.10. End User understands and irrevocably consents that it is the responsibility of the End User to
                evaluate whether any advice received via the End User Services is suitable or sufficient for the End User's
                needs, ailment or purpose, whether and to what extent the End User should rely upon such advice and/or act
                upon the same, and whether the End User should seek the assistance of any other medical or healthcare HCP.
            </p>
            <p class="py-2">
                3.11. If, following the use of the Site, you are in any doubt, or have any concerns regarding any
                information or advice you have received, or failed to receive via the End User Services, or regarding your
                health, wellbeing or any conditions, you agree to seek further opinion from a medical or healthcare HCP not
                affiliated with the Company, and irrespective of whether you seek such opinion or advice, the Site shall
                never be liable or responsible.
            </p>
            <p class="py-2">
                3.12. The Company reserves the right, at its sole discretion, to change, modify, add to, or remove any part
                of the End User Service or portions of these End User Terms of Use at any time without any prior written
                notice to the End User, and any such change, modification, addition or removal (hereinafter referred as
                "Modifications") shall be considered as a part of these End User Terms of Use. It is the End User's
                responsibility to review these End User Terms of Use periodically for updates/changes. End User's continued
                use of and access to the Site, following the Modifications, will mean that the End User accepts and agrees
                to the Modifications.
            </p>
            <p class="py-2">
                3.13. Any right granted to the End User to use the Services offered by the Company by its Site is personal,
                non-exclusive, and non-transferable, and a limited and revocable (at the discretion of the Company)
                permission to use the Site and End User Services.
            </p>
            <p class="py-2">
                3.14. Without limiting its other rights under these Terms, the Company may immediately restrict or
                deactivate your access to the Services if you breach the Community Guidelines at any time.
            </p>
            <p class="py-2">
                3.15. You may terminate these Terms at any time for any reason whatsoever. For further information on
                termination, please refer to the Termination section of this document.
            </p>
            <p class="py-2">
                3.16. If you do not wish to be bound by any part of this Agreement, you are not allowed to and shall not use
                the Site or any of Services offered by the Company or the Site. Any such use of the Site or any of the
                Services shall not be valid and shall not vest any rights or entitlements in you and shall be a breach of
                this Agreement by you.
            </p>
            <p>4. <span class="HcpSubHeading">PRIVACY POLICY</span></p>
            <p class="py-2">
                4.1. The Company has established a Privacy Policy that explains to End Users how their personal information
                is collected and used. The Privacy Policy is located at the domain, www.rxix.in/policiesandterms.
            </p>
            <p class="py-2">
                4.2. The Privacy Policy contains information about how End Users may seek access and correct their personal
                information held by the Company and how they may make a privacy complaint.
            </p>
            <p class="py-2">
                4.3. The Privacy Policy is hereby incorporated into the End User Terms of Use set forth herein that Your use
                of this Site is governed by the Privacy Policy.
            </p>
            <p class="py-2">
                4.4. The Company may disclose End Users’ personal information to third parties any necessary information
                (including your contact information), if there is a complaint, dispute or conflict, which may include an
                accident, involving you and a third party and such information or data is necessary to resolve the
                complaint, dispute or conflict. The Company may also provide to a law enforcement agency, statutory body,
                governmental agency and/or investigative agency any necessary information (including your contact
                information), if required by law or in furtherance of any investigation involving you and a third party, and
                such information or data is necessary towards the enquiry/investigation that is being carried out by the
                said body and/or agency.
            </p>
            <p>5. <span class="HcpSubHeading">COMMUNITY GUIDELINES</span></p>
            <p class="py-2">
                5.1. The Company has established Community Guidelines that explains to End Users how we would like End Users
                to post, interact, reply, comment, etc. on the Site. The Community Guidelines are located at the domain,
                www.rxix.in/policiesandterms.
            </p>
            <p class="py-2">
                5.2. The Community Guidelines contains information about how violations of the guidelines will be handled
                and how they may make a guidelines violation complaint.
            </p>
            <p class="py-2">
                5.3. The Community Guidelines are hereby incorporated into the End User Terms of Use set forth herein that
                Your use of this Site is governed by the Community Guidelines.
            </p>
            <p>6. <span class="HcpSubHeading">CAUTION</span></p>
            <p class="py-2">
                6.1. End User Services are not intended to be used in emergency healthcare.
            </p>
            <p class="py-2">
                6.2. End User Services provided by the Site shall not be used by the End User in case(s) of any kind of
                medical emergencies.
            </p>
            <p class="py-2">
                6.3. End User is also cautioned to note that the Company does not and will not compel any HCP to provide any
                services, and it shall be the sole prerogative and discretion of the HCP to provide any service/services or
                consultation. A HCP always has the right to refuse or decline its services to any End User or the Company,
                without any notice.
            </p>
            <p class="py-2">
                6.4. End User hereby agrees and understands that due to certain reasons, the treating HCP at his/her sole
                discretion, may in his/her sole discretion elect not to provide advice to End User through the Site.
            </p>
            <p class="py-2">
                6.5. End User understands that no results can be guaranteed or assured from the advice sought from the HCPs.
            </p>
            <p>7. <span class="HcpSubHeading">RELATIONSHIP AND AGREEMENT BETWEEN THE COMPANY, END USERS, AND THE HCPS
                </span></p>
            <p class="py-2">
                7.1. The Company is an intermediary as per Section 2(w) of the Information Technology Act, 2000.
            </p>
            <p class="py-2">
                7.2. The Company is not and cannot be a party to or control in any manner any dealing between the Site's End
                Users and the HCPs and the End User hereby understands and accepts as under:
            </p>
            <p class="py-2">
                7.2.1. The Company does not practice medicine or care services and does not interfere with the practice of
                medicine and is not involved in providing healthcare or medical advice or diagnosis or home care or
                diagnostic End User Services.
            </p>
            <p class="py-2">
                7.2.2. All or any of the HCPs who may provide services through our Site are independent professionals and/or
                organisations/companies who are providing services in their legal and professional capacity.
            </p>
            <p class="py-2">
                7.2.3. The `Company does not make any representations or warranties with respect to these HCPs or the
                quality of the healthcare End User Services they may provide. Accordingly, the Company shall not be held
                responsible for any interaction or associated issues between the End User and the HCP.
            </p>
            <p class="py-2">
                7.3. The End User hereby understands and agrees that the relationship between the End User and the HCP is
                and shall always be independently governed by the rules and regulations that may be prescribed by the HCP,
                and/or under Applicable Laws. The Company does not claim any control over any such rules and regulations and
                Applicable Laws.
            </p>
            <p class="py-2">
                7.4. There is and shall always be a separate and independent contractual relationship between the End User
                and the HCP, without any involvement or control or interference of the Company. Accordingly, there is and
                shall never be any privity of contract between the Site, the End User and the HCP.
            </p>
            <p class="py-2">
                7.5. The End User undertakes and agrees that the End User shall independently enquire, ask for and will
                arrive at an individual or specific agreement with the HCP, whose services may be available on the Site.
            </p>
            <p class="py-2">
                7.6. The End User shall note that the Company is not privy to any separate or independent Agreement that the
                End User has with any HCP, which independently governs their inter-se relationship, and shall be on a
                principal-to-principal basis between them.
            </p>
            <p class="py-2">
                7.7. The Company shall not be held liable for any interaction and associated issues, including but not
                limited to the outcome and/or service issues, between the End User and the HCP/s. Hence, the Company shall
                not be held liable for such interactions. If you decide to engage with a HCP to provide End User Services to
                you, you do so at your entire risk and liabilities in all respects. The Company shall not be liable for any
                reason whatsoever for the End User Services provided by HCP, and we bear no liability for the consequences
                to you, of your use of the system or End User Services.
            </p>
            <p>8. <span class="HcpSubHeading">END USER OBLIGATIONS, UNDERTAKINGS AND CONSENT</span></p>
            <p class="py-2">
                8.1. Prior to availing any Services, or completion of any transaction on the Site, you will have to register
                and become a "Registered End User".
            </p>
            <p class="py-2">
                8.2. All End Users shall be of 18 years of age or above and shall register separately to avail the Services.
                The legal age of majority, 18 years, shall apply and bind the End User, irrespective of the legal age of
                majority that is applicable to the jurisdiction in which the End User resides or is domicile.
            </p>
            <p class="py-2">
                8.3.When an End User registers on the Site, the End User shall be required to create an account ("End User
                Account") by providing information comprising the name, email address, mobile number, password, date of
                birth, gender, city, pincode and such other information as may be required by the Company.
            </p>
            <p class="py-2">
                8.4. The verification of mobile number and/or the email shall be compulsory without which the Company will
                not create a End User Account.
            </p>
            <p class="py-2">
                8.5. Use of the Site and the Services by the End User shall be solely at the discretion of the Company. The
                Company reserves its right to add, delete or discontinue its any Service or Site, in part or whole.
            </p>
            <p class="py-2">
                8.6. The Company reserves, at its sole discretion, the right to refuse any End User to access the Site or
                avail any Services without assigning any reason.
            </p>
            <p class="py-2">
                8.7. The Company also reserves, at its sole discretion, the right to block any End User from creation of any
                End User Account or block any Registered End User from accessing Site or Services, without assigning any
                reason.
            </p>
            <p class="py-2">
                8.8. If an End User registers on the Site and successfully gets an End User Account, the End User shall be
                responsible for maintaining the confidentiality of the account, and the End User shall be responsible for
                all activities that occur under the End User's Account.
            </p>
            <p class="py-2">
                8.9. The End User hereby declares and verifies that all information provided by the End User is true,
                accurate and genuine. The End User hereby agrees that in case any information provided by the End User is
                not true or accurate or genuine or complete, or the Company has reasonable grounds to suspect that such
                information is false, misleading, untrue, inaccurate, not current or incomplete, or not in accordance with
                these End User Terms of Use, the Company shall have the right to indefinitely suspend or terminate or block
                access of End User's Account on the Site.
            </p>
            <p class="py-2">
                8.10. The End User hereby agrees, declares and warrants that the End User is fully authorized and has taken
                all requisite approvals from any person on whose behalf (if any) the End User is acting on the Site.

            </p>
            <p class="py-2">
                8.11. The End User agrees that the End User shall be solely responsible for all usage of the End User's
                Account, whether authorized by the End User or not.
            </p>
            <p class="py-2">
                8.12. The End User consents to immediately notify the Company of any actual or suspected unauthorized use or
                breach of the End User's Account. The End User hereby agrees that the Company shall not be liable for any
                direct or indirect damages, losses, costs, charges, expenses, etc. caused to the End User by any
                unauthorized use of the End User Account, and the End User shall be liable for all damage and loss caused to
                the Company or others, due to such unauthorized use.
            </p>
            <p class="py-2">
                8.13. The End User agrees and undertakes that the End User will not or attempt to:
            </p>
            <p class="py-2">
                8.13.1. mpersonate any other End User, person or entity;
            </p>
            <p class="py-2">
                8.13.2. misrepresent his affiliation with the Company (there being no such affiliation);
            </p>
            <p class="py-2">
                8.13.3. use the Site or Services in violation of any local, state, national or international laws, rules or
                regulations;
            </p>
            <p class="py-2">
                8.13.4. obtain any Services from the Site, using his account for any person, except for himself and his
                family;
            </p>
            <p class="py-2">
                8.13.5. use the Services, Site or System in any manner that exceeds the scope of the right to use the Site,
                System and Services;
            </p>
            <p class="py-2">
                8.13.6. use abusive, inappropriate or derogatory language while communicating with the HCP or with other
                Registered End Users or with the Company through the Site.
            </p>
            <p class="py-2">
                8.14. The End User agrees that the delivery of any communications from the Company shall be deemed to be
                effective, when sent by the Company, through any mode of communication, regardless of whether the End User
                reads the communication on receipt of it, or whether the End User actually receives the communication.
            </p>
            <p>9. <span class="HcpSubHeading">DISCLAIMER OF WARRANTIES, GUARANTEES AND REPRESENTATIONS</span></p>
            <p class="py-2">
                9.1. End User agrees that the use of the End User Services is entirely at the End User's own risk, cost and
                consequences. The Services are provided on 'as is' basis, without warranty or guarantee of any kind, and/or
                any responsibility or liability, either expressed or implied, or vicarious, or contingent.
            </p>

            <p class="py-2">
                9.2. We make no representations concerning the completeness, accuracy or utility of any information in the
                System, or concerning the qualifications or competence of individuals who placed it there.
            </p>

            <p class="py-2">
                9.3. While every effort has been made to ensure that the information hosted on this Site is accurate and
                correct; the Company does not warrant the accuracy of information obtained from the Site or that it will not
                violate or infringe any third party right in any manner, whatsoever.
            </p>

            <p class="py-2">
                9.4. The Company makes no representation or warranty regarding the standing, qualification, ability,
                capacity, experience, competence, professionalism, bonafides, credit worthiness or otherwise of any HCP or
                company offering Services through the Site, or any information, or the suitability of information, for any
                purpose, whatsoever. The Company displays on the Site, relevant information regarding the profile and
                practice of the HCPs listed on the Site, as may be made available and provided to the Company. Such
                information found on the Site is provided and self-reported by the HCP, and the Company makes no assurance,
                representation or warranty as to the accuracy, completeness or genuineness of the same. Such information
                often changes frequently and may become out of date or inaccurate. Neither the Site nor the Company provides
                any advice, or qualification or certification, about any HCP. The End User is encouraged and advised to
                independently verify any such information that the End User may see on the Site with respect to the HCP. The
                Company further makes no representation or gives any warranty, either expressed or implied, with regard to
                any information or End User Services available on the Site.
            </p>
            <p class="py-2">
                9.5. The Company does not make any representations or warranties with respect to any HCP(s) or the quality
                of the End User Services they may provide to the End User.
            </p>
            <p class="py-2">
                9.6. The Company disclaims all warranties, including, but not limited to, the implied warranty of
                merchantability and suitability of any Services.
            </p>

            <p class="py-2">
                9.7. Company also, does not warrant that:
            </p>
            <p class="py-2">
                9.7.1. this Site will be constantly available, or available at all;
            </p>
            <p class="py-2">
                9.7.2. any or all the End User Services or any other Services on the Site will be constantly available, or
                available at all;
            </p>
            <p class="py-2">
                9.7.3. the End User will be able to access his or her account at any or all times;
            </p>
            <p class="py-2">
                9.7.4. the information on this Site is complete, true, accurate or non-misleading;
            </p>
            <p class="py-2">
                9.7.5. the warranties, endorsements, representations or opinions made by other End Users and HCPs, the
                content or information provided by the End Users and/or HCPs on the Site, or any opinion or suggestion given
                or expressed by the Company or any End User in relation to any End User or End User Services provided by
                HCP(s) is complete, true, accurate or non-misleading;
            </p>
            <p class="py-2">
                9.7.6. the quality of any Services, information, or any other thing obtained by the End User through the
                Site will meet the End User's expectations, need or purpose;
            </p>
            <p class="py-2">
                9.7.7. the End User or any other person will be able to use any Services;
            </p>
            <p class="py-2">
                9.7.8. the End User will be able to complete any or all the transactions on the Site;
            </p>
            <p class="py-2">
                9.7.9. the HCP will properly fulfill his obligations in any transaction;
            </p>
            <p class="py-2">
                9.7.10. the feedbacks and reviews provided by the End User will be published on the Site; and
            </p>
            <p class="py-2">
                9.7.11. the Site, information, content, materials, or End User Services included on or otherwise made
                available to the End User through the Site; their servers; or electronic communication sent from the Company
                or the Site, are free of viruses or any other harmful components.
            </p>
            <p class="py-2">
                9.8. The Company assumes no responsibility, and shall not be liable for any damages to, or viruses that may
                infect End User's equipment on account of the End User's access to, use of, or browsing the Site or the
                downloading of any material, data, text, images, video content, or audio content from the Site. If an End
                User is dissatisfied with the Site, End User's sole remedy is to discontinue using the Site.
            </p>
            <p>10. <span class="HcpSubHeading">END USER SERVICES</span></p>
            <p class="py-2">The Company offers End User Services through the Site. While some End User Services available on
                the Site are provided at no charge, for free, there are certain End User Services for which charges will be
                incurred and payable by you.
            </p>
            <p class="py-2">The specific terms relating to such End User Services, without prejudice and in addition to all
                other End User Terms of Use are as under.
            </p>
            <p class="py-2">
                10.1. REQUEST HOME VISIT
            </p>
            <p class="py-2">
                10.1.1. The Company will reasonably endeavour to arrange a confirmed appointment for a home visit by a HCP
                for a User, who requests the same on Site. However, the Company does not guarantee or assure that an End
                User will get a confirmed appointment within a particular time frame.
            </p>
            <p class="py-2">
                10.1.2. It may happen that a HCP cancels an End User’s home request. In such a scenario, the Company will
                make attempts to schedule a home visit by an alternate HCP. The Company may also get in touch with the End
                User to reschedule the home visit request, if need be. If none of these endeavors result in a home visit to
                the End User, the amount paid by the End User shall be refunded to the End User within 72 hours. The amount
                will be refunded in the same mode as it was paid to the Company.
            </p>
            <p class="py-2">
                10.1.3. The End User has the right to cancel a home visit request before the HCP visits the house, as
                requested for.
            </p>
            <p class="py-2">
                10.1.4. If the End User cancels his home visit request, the amount paid by the End User will be refunded to
                the End User within 72 hours. The amount will be refunded in the same mode as it was paid to the Company.
                The calculation of the amount that will be refunded is as follows.
            </p>
            <p class="py-2">
                10.1.4.1. When the time difference between the cancellation time and the scheduled visit time is 24 hours or
                more, 100% of the amount will be refunded.
            </p>
            <p class="py-2">
                10.1.4.2. When the time difference between the cancellation time and the scheduled visit time is greater
                than 6 hours but less than 24 hours, 75% of the amount will be refunded.
            </p>
            <p class="py-2">
                10.1.4.3. When the time difference between the cancellation time and the scheduled visit time is less than 6
                hours, 50% of the amount will be refunded.
            </p>

            <p class="py-2">
                10.2. SEARCH FOR AN RxIx-SUPPORTED CLINIC
            </p>
            <p class="py-2">
                10.2.1. You may search for HCPs who are registered with the Site in a particular geography or using such
                other filters, as may be provided by the Company.
            </p>
            <p class="py-2">
                10.2.2. The Company's ranking algorithm for listing the HCPs is a fully automated system that lists the HCPs
                on the Site and links their profile and information to a HCP’s page, if available.
            </p>
            <p class="py-2">
                10.2.3. This listing of HCPs does not represent any fixed objective ranking or any preferential or
                meritorious endorsement by the Company. The Company will not be liable for any change in the listing order
                of the HCPs, which may take place from time to time when you search for relevant End User Services and HCPs.
                The Company, in no event, will be held responsible for the accuracy and the relevancy of the listing order
                of the HCPs on the Site.

            </p>
            <p class="py-2">
                10.2.4. The information displayed on the HCP’s page is self-reported by the HCP, and the Company makes no
                assurance, representation or warranty as to the accuracy, completeness or genuineness of the same. Such
                information often changes frequently and may become out of date or inaccurate. The End User is encouraged
                and advised to independently verify any such information that the End User may see on the HCP’s page.
            </p>
            <p class="py-2">
                10.3. GET FEEDBACK ABOUT A HCP
            </p>
            <p class="py-2">
                10.3.1. You may raise a request to get feedback about a HCP.
            </p>
            <p class="py-2">
                10.3.2. Upon raising a request, the Company shall provide you with the name and contact particulars of a
                Registered End User or a Person you can contact to get feedback about the HCP.
            </p>
            <p class="py-2">
                10.3.3. The Company, in no event, will be held responsible for the accuracy and the relevancy of the
                feedback provided by the Person.
            </p>
            <p class="py-2">
                10.3.4. The Company does not endorse or influence or assure the correctness of the feedback provided by the
                Person.
            </p>
            <p class="py-2">
                10.3.5. The Company makes no assurance, representation or warranty as to the accuracy, completeness or
                genuineness of the feedback. The End User is encouraged and advised to independently verify any such
                information that the End User may receive from the Person.
            </p>
            <p class="py-2">
                10.3.6. You shall not cancel your request for feedback. The Company shall also not refund any amount paid by
                you for this service.
            </p>
            <p class="py-2">
                10.4. JOIN PATIENT SUPPORT GROUPS
            </p>
            <p class="py-2">
                10.4.1. The Company may time to time launch condition-specific or healthcare specialty-specific patient
                support groups. These will be available on the Site as online communities. Only Registered End Users may
                join such patient support groups. Such patient support groups will be managed by the Company.
            </p>
            <p class="py-2">
                10.4.2. Based on the End User’s healthcare topics of interest, their consultation with the HCPs registered
                on the Site or based on other means as may be defined by the Company, the Company may enrol End Users into
                select patient support groups. In addition, End Users may also voluntarily join patient support groups.
            </p>
            <p class="py-2">
                10.4.3. The Company may choose to restrict the number of support groups that an End User may join.
            </p>
            <p class="py-2">
                10.4.4. You have the right to disassociate yourself from any of the support groups at any time.
            </p>
            <p class="py-2">
                10.5. JOIN ONLINE COMMUNITIES OF HCPS
            </p>
            <p class="py-2">
                10.5.1. The Company may enable HCPs to setup their online communities on the Site. Subsequently, the HCPs
                registered on the Site may also launch their communities. Such a community will be managed by the respective
                HCP.
            </p>
            <p class="py-2">
                10.5.2. Based on the End User’s consultation with the HCPs registered on the Site, the Company may enrol End
                Users into the community managed by the HCP. In addition, End Users may also voluntarily join a HCP’s online
                community.
            </p>
            <p class="py-2">
                10.5.3. The Company may choose to restrict the number of HCP communities that a End User may join.
            </p>
            <p class="py-2">
                10.5.4. You have the right to disassociate yourself from any of the communities at any time.
            </p>
            <p class="py-2">
                10.6. GET ANSWERS FROM OTHER END USERS AND/OR PUBLISH YOUR EXPERIENCE/KNOWLEDGE TO OTHER END USERS
            </p>
            <p class="py-2">
                10.6.1. You may be allowed to post any question on the Site with the objective of getting answers to your
                queries or publish any information to other End Users that is not commercial or soliciting in nature.
            </p>
            <p class="py-2">
                10.6.2. When you post any question or information, the same may be sent to End Users and HCPs who are part
                of the support groups and communities that you are part of.
            </p>
            <p class="py-2">
                10.6.3. The End User understands and agrees that the question and any answer provided by the End Users and
                HCPs, or the information and the related comments will become part of the Company's public database. We
                recommend that you DO NOT disclose your personal details as part of the question or information. The Company
                subsequently lists these questions, information and their responses on the Site so that the End Users at
                large can benefit from them. With regard to questions raised by the End User, the Company may not display
                the name, age or gender of the End User who posts the question on the Site. With regard to publishing
                information, the Company may display the name of the End User who publishes such information.
            </p>
            <p class="py-2">
                10.6.4. When you post information or raise a question, there could be a delay in the End User obtaining a
                response and in some cases, there may be no response at all.
            </p>
            <p class="py-2">
                10.6.5. The Company will not be held liable for any inconvenience that may be caused to the End User on
                account of such delay or non-response. The Company makes no guarantee, warranty, assurance or undertaking
                regarding the receipt of reply or time frame within which you should expect to receive a reply.
            </p>
            <p class="py-2">
                10.6.6. All replies to your questions and comments to your posts are for general information purposes only.
                Replies are not intended and shall not be treated as personalised health advice for the End User and
                follow-up questions from the End User may not receive any reply.
            </p>
            <p class="py-2">
                10.7. REFER A LIBRARY OF HEALTHCARE INFO
            </p>
            <p class="py-2">
                10.7.1. A library of information is accessible only to Registered End Users.
            </p>
            <p class="py-2">
                10.7.2. End Users may access the library of healthcare resources. These resources may include videos, texts,
                and images. Some of the resources may be links to third party sites. Access to such links may be governed by
                the policies and rules of such third-party sites.
            </p>
            <p class="py-2">
                10.7.3. The Company does not claim authenticity of any information provided in the library. The information
                is provided on an "as is" basis and the Company does not validate the said information and makes no
                representation or assurance in connection therewith.
            </p>
            <p class="py-2">
                10.8. RECEIVE NEWSLETTERS AND RELATED COMMUNICATION
            </p>
            <p class="py-2">
                10.8.1. HCPs may send newsletters and other information that they consider relevant to End Users. These
                communications and resources are not endorsed by the Site.
            </p>
            <p class="py-2">
                10.8.2. You will receive such communication from HCPs whose communities you have joined or who are members
                of the support groups that you are also a member of.
            </p>
            <p class="py-2">
                10.8.3. The Company may publish posts that are relevant to End Users and to their activity on the Site.
            </p>
            <p class="py-2">
                10.8.4. The Company will choose the publication periodicity of such communication.
            </p>
            <p class="py-2">
                10.8.5. You may also receive posts and questions from other Registered End Users.
            </p>
            <p class="py-2">
                10.8.6. You may also receive sponsored communication from companies or individuals.
            </p>
            <p class="py-2">
                10.8.7. Such communication will reach your Home page on the Site. In addition, depending on your settings,
                you will receive notification of such communication on your mobile and/or mail ID.
            </p>
            <p class="py-2">
                10.9. STORE HEALTH AND MEDICAL RECORDS
            </p>
            <p class="py-2">
                The Company makes available a facility for online storage of medical records that End Users may access and
                use. The specific terms relating to such storage are below, without prejudice and in addition to all other
                End User Terms of Use.
            </p>
            <p class="py-2">
                10.9.1. Your storage facility is created only after you have signed up and explicitly accepted all these
                Terms of Use.
            </p>
            <p class="py-2">
                10.9.1.1. You may add your dependents to your End User Account and each of the dependents will be
                individually provided with a storage facility.
            </p>
            <p class="py-2">
                10.9.1.2. The Company may, at its discretion, limit the number of dependents that an End User can add to one
                End User Account.
            </p>
            <p class="py-2">
                10.9.1.3. You own the contents of your storage facility. You have the rights to remove and share the records
                stored in your storage facility.
            </p>
            <p class="py-2">
                10.9.1.4. The Company may, at its discretion, charge you for the storage facility.
            </p>
            <p class="py-2">
                10.9.2. In the interests of your care, you agree and provide irrevocable consent to the Company that it may
                provide access to a HCP who has provided you or will provide you consultation, the records present in your
                online storage facility. Further, you agree and provide your irrevocable consent that the HCP may, at his
                discretion, share your records with other HCPs, whether on the Site or not, with the objective of obtaining
                insights that will help him provide improved care to You.
            </p>
            <p class="py-2">
                10.9.3. The records will be stored as long as you are a Registered End User of the Site. If you terminate
                your account, a link will be provided to the registered mail ID that will allow you to download your
                records. The link will be active for 7 days only. It is your responsibility to keep your correct mobile
                number and email ID updated in the Account. The records will be sent to the End User associated with the
                registered mobile number and/or email ID. The Company is not responsible for any loss or inconvenience
                caused due to non-updation of contact details for the Account. For further detail about termination, please
                refer the section on Termination.
            </p>
            <p class="py-2">
                10.9.4. Information available in the storage facility will be of two types:
            </p>
            <p class="py-2">
                10.9.4.1. End User-created: Health and medical information that you upload to your storage area. Once you
                request for a home consultation service, you unconditionally agree that the information will be shared with
                the HCP who will provide you the consultation. You may also choose to share your records with any other HCP
                or End User who is registered/ not registered on the Site. It is your responsibility to ensure that only the
                intended people have access to your records. You agree that the Company is not responsible if any other End
                User accesses your records.
            </p>
            <p class="py-2">
                10.9.4.2. HCP-created: Information generated pursuant to your interaction with a HCP. The sharing of this
                information too follows the same pattern as the End User created information.
            </p>
            <p class="py-2">
                10.9.4.3. Any HCP-created information will be provided on an "as is" basis and the Company does not validate
                the said information and makes no representation or assurance in connection therewith. You should contact
                the relevant HCP in case you wish to point out any discrepancies or if you wish to add, delete, or modify
                the information in any manner.
            </p>
            <p class="py-2">
                10.9.5. The storage facility is provided on a best-effort and on "as is" basis. While we endeavour to
                maintain high levels of service availability, the Company is not liable for any interruption in the access
                of the End User Services.
            </p>
            <p class="py-2">
                10.9.6. The Company uses current industry-level security and encryption in the System. However, the Company
                cannot prevent unauthorized access in case of loss or misuse of your login credentials or if they are
                otherwise compromised in any manner. End Users are responsible for safeguarding their login credentials of
                the End User Account and to immediately report any actual or suspected breach of account to the Company.
            </p>
            <p class="py-2">
                10.9.7. If you access your dependents' or family members records through your End User Account by
                registering your dependents with your own Account, you are and will be deemed to be responsible for the
                records of your dependents and all obligations that your dependants would have had, had they maintained
                their own separate individual storage facility, and in such a case, all the Terms and Conditions shall
                automatically apply to and bind your dependants, as if they have entered into this Agreement.
            </p>
            <p>11. <span class="HcpSubHeading">FEES AND PAYMENT</span></p>
            <p class="py-2">
                11.1. Should you wish to avail paid End User Services, you acknowledge that the Company may collect Service
                charges for such End User Services for and on behalf of the HCP providing you such End User Services, but
                with no liability or obligation, express or implied, being assumed by the Site.
            </p>
            <p class="py-2">
                11.2. In addition to the Service charges, the Company may charge you a convenience fee (“Convenience Fee”)
                for the End User Services provided by Site to you. The Service charges and the Convenience Fee shall be
                charged from you at the time of booking/ accessing such End User Paid Services on the Site.
            </p>
            <p class="py-2">
                11.3. It is clarified that the Service charges and Convenience Fees are payable only for the services to be
                provided by the HCP to you and for the Services provided by the Site to you, respectively.
            </p>
            <p class="py-2">
                11.4. The prices of the paid End User Services will be displayed when you avail such Services. The prices
                displayed on the Site may or may not include any taxes, including Service Tax, Goods and Services Tax, VAT,
                etc., as applicable and the same will, be additionally payable by the End User, as applicable.
            </p>
            <p class="py-2">
                11.5. You acknowledge and confirm that the Company shall never be liable for the treatment or be treated as
                the healthcare service provider on account of collection of the payments in relation to, or on account of,
                paid End User Services, for any reason whatsoever.
            </p>
            <p class="py-2">
                11.6. For making the payment, the End User will be directed to the portal of the third party, who has been
                outsourced by the Company, to receive payment.
            </p>
            <p class="py-2">
                11.7. For any of the payment method opted by the End User for making payment on the portal of the outsourced
                party/agency/service provider, and thereafter by entering banking information and details on that portal
                while making payment, the Company does not assume any liability, and shall never be responsible for any
                direct or indirect costs, charges, expenses, damage or loss suffered by the End User, as the Company has
                outsourced third party(s) for receiving such payment, who is an independent party, and the Company has no
                access or control over any information and details inserted by the End User when making or attempting to
                make any payment. Unless otherwise stated, all fees shall be quoted in Indian Rupees (INR).
            </p>
            <p class="py-2">
                11.8. The liability of the Company to render Services, shall arise only after receipt of the payment, on
                successful completion of the payment transaction.
            </p>
            <p class="py-2">
                11.9. The Company may, at its sole discretion, introduce any offers/ discounts/ incentives on the Services,
                or introduces new service and/ or modify some or all of the existing services offered on the Site. In such
                an event, the Company reserves the right to charge fees for the new services offered or amend/introduce fees
                for existing services, without any prior intimation.
            </p>
            <p class="py-2">
                11.10. The Company may choose to discontinue or modify any incentive(s) or discounts at its sole discretion,
                without any prior intimation or notice.
            </p>
            <p class="py-2">
                11.11. End User shall be solely responsible for compliance to all applicable laws of India while making any
                payment to Company.
            </p>
            <p class="py-2">
                11.12. The Company may, at its sole discretion, create promotional codes that may be redeemed for Account
                credit, or other features or benefits related to the End User Services and/or Paid End User Services,
                subject to any additional terms that the Company establishes on a per promotional code basis ("Promo
                Codes"). You agree that Promo Codes: (i) must be used for the intended audience and purpose, and in a lawful
                manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general
                public (whether posted to a public form or otherwise), unless expressly permitted by the Company; (iii) may
                be disabled by the Company at any time for any reason, without liability to the Company; (iv) may only be
                used pursuant to the specific terms that the Company establishes for such Promo Code; (v) are not valid for
                cash; and (vi) may expire prior to your use. The Company reserves the right to withhold or deduct credits or
                other features or benefits obtained through the use of Promo Codes by you or by any other end user, in the
                event that the Company reasonably determines or believes that the use or redemption of the Promo Code was in
                error, fraudulent, illegal, or in violation of the applicable Promo Code terms or these Terms.
            </p>
            <p class="py-2">
                11.13. The Company reserves the right to establish, remove and/or revise Service charges and Convenience Fee
                for any or all services obtainable through the use of the Paid End User Services at any time at its sole
                discretion. The Company may, from time to time, provide certain end users with promotional offers and
                discounts that may result in different amounts charged for the same or similar services obtained through the
                use of the Services, and you agree that such promotional offers and discounts, unless also made available to
                you, shall have no bearing on your use of the Services or the Service Charges applied to you.
            </p>
            <p class="py-2">
                11.14. Further, the Company may, from time to time, offer subscription packages to you, wherein, in exchange
                for monetary consideration, additional benefits such as discounted services will be made available to you.
                The said benefits may only be available for a limited period as may be agreed with you in advance.
            </p>
            <p class="py-2">
                11.15. After you have received services through the Paid End User Services, you will have the opportunity to
                rate your experience and leave additional feedback, if any, about the service availed.
            </p>
            <p>12. <span class="HcpSubHeading">VIOLATIONS OF SYSTEM OR NETWORK SECURITY</span></p>
            <p class="py-2">
                12.1. Subject to the terms of this Agreement, and any other agreement that the Company may have with the End
                User, the End User shall act in an honest, bonafide and ethical manner at all times when the End User
                accesses the Site or Uses any of the End User Services and undertakes to provide all co-operation that is
                reasonably required by the Company in relation to the Site and Services.
            </p>
            <p class="py-2">
                12.2. Any violation or breach of the System or network security is prohibited and actionable, and any such
                act may result in the End User facing criminal and civil liability and being denied access to the Site.
                Violations and breach of Site, System or network security may include, but are not limited to, the
                following:
            </p>
            <p class="py-2">
                12.2.1. introduction of any virus, logic bomb, harmful code and/or Trojan horse to the Site or Service.
            </p>
            <p class="py-2">
                12.2.2. flaming, hacking or otherwise obtaining unauthorized access to or use of data, systems or networks,
                including any attempt to probe, scan or test the vulnerability of a system or network.
            </p>
            <p class="py-2">
                12.2.3. unauthorized monitoring of data or traffic on any network or system without express authorization of
                the Company of the system or network.
            </p>
            <p class="py-2">
                12.2.4. interfering with any End User, host or network, including mail bombing, flooding, deliberate
                attempts to overload a system and broadcast attacks.
            </p>
            <p class="py-2">
                12.2.5. interference with any other End User's use and enjoyment of the Site or Service, or any other
                individuals' use and enjoyment of similar services in any manner.
            </p>
            <p class="py-2">
                12.2.6. placing on the Site or Service, any misleading, defamatory, obscene, offensive or indecent material
                or material which breaches any intellectual property rights of others.
            </p>
            <p class="py-2">
                12.2.7. reverse engineering, disassembling, decompiling, or translating any software or other components of
                the Site, System or Services;
            </p>
            <p class="py-2">
                12.2.8. any copyright violation of the Site or System;
            </p>
            <p class="py-2">
                12.2.9. transmitting any information (including job posts, messages and hyperlinks) on or through the Site
                that is disruptive or competitive to the provision of End User Services by the Company;
            </p>
            <p class="py-2">
                12.2.10. making any unsolicited communications to other End Users;
            </p>
            <p class="py-2">
                12.2.11. framing or hot linking or deep linking any the Company Content.
            </p>
            <p class="py-2">
                12.3. End User agrees not to use any device, software or routine to interfere or attempt to interfere with
                the proper working of the Site or System, or any transaction being conducted on the Site or System, or with
                any other person's use of the Site or System.
            </p>
            <p class="py-2">
                12.4. The Company reserves and shall have all the rights to take necessary actions, at its sole discretion,
                including claiming damages that may occur due to End User's above acts or involvement/participation in any
                way on its own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed
                Denial of Services).
            </p>
            <p>13. <span class="HcpSubHeading">DATA RETENTION</span></p>
            <p class="py-2">
                13.1. End User agrees that it is the End User's responsibility to ensure and keep a copy of all data
                pertaining to the End User account, medical or otherwise, in End User's own computer or any other place or
                in any other form.
            </p>
            <p class="py-2">
                13.2. End User hereby declares that the End User understands and agrees that the Company is not obliged to
                keep or preserve data of the End User, and there may be hardware, System or network failure of the Site,
                including data storage facility of the Company, which may result in partial or total loss of data, and in
                case of such loss of data, the Company shall not be obliged or held responsible to retrieve or restore or
                provide a copy of the data to the End User, with or without cost; and further the Company shall not be held
                responsible for any payment or compensation or damages to the End User, for any such data loss or failure of
                the Company to retrieve or restore or provide a copy of the data to the End User, and it shall be the sole
                and exclusive responsibility of the End User to keep a copy of the data, with respect to anything related to
                the End User Account.
            </p>
            <p>14. <span class="HcpSubHeading">INFORMATION UPLOADED BY THE END USER ON THE SITE</span></p>
            <p class="py-2">
                14.1. While the Company takes utmost care with respect to the security of the information you decide to
                upload, you understand that any information that you disclose on the Site, is at your own risk. By uploading
                / sharing / disclosing your medical conditions, medical history, medical records, and such other health
                records and any other information on the Site, you hereby give your consent to the Company to store such
                health / medical information on the Company's servers.
            </p>
            <p class="py-2">
                14.2. Some information provided by the End Users on the Site may qualify as "Sensitive personal data or
                information", as defined under The Information Technology (Reasonable Security Practices and Procedures and
                Sensitive Personal Data or Information) Rules, 2011, for which Company has a separate Privacy Policy.
            </p>
            <p class="py-2">
                14.3. The Company will retain your medical conditions, medical history, medical records, and such other
                health records and any other information that you may upload on the Site for as long as it is needed to
                fulfil the service you seek to avail on the Site. If you terminate your account, the Company will delete all
                such medical / health information. But please note: (1) there might be some latency in deleting this
                information from our servers and back-up storage; and (2) we may retain this information, for the period
                permitted under the laws of India, if necessary to comply with our legal obligations, for historical and
                statistical purposes, resolve disputes, or enforce our agreements.
            </p>
            <p>15. <span class="HcpSubHeading">COMMUNICATION, SMS MESSAGES, MAILS, CALLS RECEIVED FROM THE SITE:</span></p>
            <p class="py-2">
                15.1. When you use the Site or access any material, information or Services through the Site, you agree and
                understand that you are communicating with us, through electronic medium and you consent to receive
                communications, through any mode of communication from Company, time to time, as and when required by
                Company, for any purpose.
            </p>
            <p class="py-2">
                15.2. By verifying the mobile number at the time of creation of the End User Account and by registering as a
                Registered End User on the Site, End User consents to be contacted by Company via phone, and/or SMS
                notifications, IM, email or any other utility, application, method or mode of communication, and authorizes
                the Company, to send any information or communication relating to the Services availed by the End User from
                the Company or any HCP, newsletter, promotional material, advertisements, or to contact for feedbacks,
                reviews, etc. or for any other purpose, including the communications relating to your registration,
                transactions that you carry out through the Site, reviews, feedbacks, and promotions that are undertaken by
                the Company. Further, the Company may also send notifications and reminders with respect to other End User
                Services that you have subscribed to on the Site. Please note that while the Company endeavours to provide
                these notifications and reminders to you promptly, the Company does not provide any guarantee and shall not
                be held liable or responsible for the failure to send such notifications or reminders to you. It is the End
                User's responsibility to ensure that you attend any appointments and consultations that you may schedule
                with a HCP through the Site.
            </p>
            <p>16. <span class="HcpSubHeading">IPR, TRADEMARK, COPYRIGHT - USE, RESTRICTIONS AND LIMITATIONS</span></p>
            <p class="py-2">
                16.1. The Site is held, controlled and operated by the Company, and all Intellectual Property Rights /IPR
                including copyright in the Site and Service solely and exclusively belongs to and owned by the Company. All
                intellectual property rights in and title to the Site, System and Services, the present or future
                modifications / upgradations thereof and standard enhancements thereto shall remain the property of the
                Company
            </p>
            <p class="py-2">
                16.2. All IPR-protected material on the Site, including images, text, illustrations, audio clips,
                trademarks, logos, labels, video clips and End User Information, are the intellectual property of the
                Company and / or of their respective owner(s). The IPR, including the End User Information that is collected
                by the Company from the End Users and the HCP(s), are subject to the privacy policy of the Company and shall
                be owned and controlled by the Company. Any redistribution, modification or reproduction of part or all of
                the contents featured in the Site or Service in any form is prohibited and actionable. You are not permitted
                to distribute or commercially exploit the IPR or the End User Information. Nothing in these End User Terms
                of Use grants the End User any right in respect of Intellectual Property Rights of Company.
            </p>
            <p class="py-2">
                16.3. This Agreement permits you to use the Site only for your personal, non-commercial use only. You must
                not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
                republish, download, store or transmit any of the material on our Site except as generally and ordinarily
                permitted through the Site according to these End User Terms of Use. You must not access or use, for any
                commercial purposes, any part of the Site or any End User Services or materials available through the Site,
                except allowed under the Agreement or any other agreement.
            </p>
            <p>17. <span class="HcpSubHeading">FORCE MAJEURE</span></p>
            <p class="py-2">
                17.1. Neither Party shall be liable nor deemed in default for failure to fulfill any obligation under this
                Agreement due to causes beyond its reasonable control. Such causes or conditions shall include, but shall
                not be limited to, acts of God or of the public enemy, acts of the Government in either its sovereign or
                contractual capacity, fires, floods, epidemics, quarantine restrictions, strikes, shortages of labour or
                materials, freight embargoes, unusually severe weather, electrical power failures, telecommunication or
                internet backbone outages, failure of an internet access provider or other similar causes beyond the
                Parties' control, and neither Party shall be liable for losses, expenses or damages, ordinary, special,
                remote or consequential, resulting directly or indirectly from such causes.
            </p>
        </div>
        <p>18. <span class="HcpSubHeading">TERMINATION</span></p>
        <p class="py-2">
            18.1. End User may deactivate the End User Account and end the registration at any time, for any reason, by
            writing to us through the Contact Us form on the Site. The Company may suspend or terminate your use of the
            Site, your Account and/or registration for any reason at any time, at its sole discretion. The Company reserves
            the right to maintain, delete or destroy all communications and materials posted or uploaded to the Site
            pursuant to its internal record retention and/or content destruction policies. After such termination, the
            Company will have no further obligation towards the End User, except to the extent we are obligated to provide
            you access to your health records available in the online storage facility for a limited duration.
        </p>
        <p>19. <span class="HcpSubHeading">RRIGHTS AND OBLIGATIONS RELATING TO CONTENT</span></p>
        <p class="py-2">
            18.1. As mandated by Regulation 3(2) of the Information Technology (Intermediaries guidelines) Rules, 2011 <a
                href="http://deity.gov.in/sites/upload_files/dit/files/GSR314E_10511(1).pdf" target="_blank">(IG Rules)</a> , the Company
            hereby informs End
            Users that they are not permitted to host, display, upload, modify, publish, transmit, update or share any
            information that:
        </p>
        <p class="py-2">
            19.1.1. belongs to another person and to which the End User does not have any right to;
        </p>
        <p class="py-2">
            19.1.2. is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous,
            invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or
            encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
        </p>
        <p class="py-2">
            19.1.3. harm minors in any way;
        </p>
        <p class="py-2">
            19.1.4. infringes any patent, trademark, copyright or other proprietary rights;
        </p>
        <p class="py-2">
            19.1.5. violates any law for the time being in force;
        </p>
        <p class="py-2">
            19.1.6. deceives or misleads the addressee about the origin of such messages or communicates any information
            which is grossly offensive or menacing in nature;
        </p>
        <p class="py-2">
            19.1.7. impersonate another person;
        </p>
        <p class="py-2">
            19.1.8. contains software viruses or any other computer code, files or programs designed to interrupt,
            destroy or limit the functionality of any computer resource;
        </p>
        <p class="py-2">
            19.1.9. threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with
            foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents
            investigation of any offence or is insulting any other nation.
        </p>
        <p class="py-2">
            19.1.10. the Company, upon obtaining knowledge by itself or any third party of any of the above acts by an End
            User, then it shall be entitled to remove or disable access to the material or information that is in
            contravention of this Agreement and to immediately terminate the access or usage rights of the End User to the
            Site.
        </p>
        <p class="py-2">
            19.2. The Company may disclose or transfer End User Account information to its affiliates or governmental
            authorities in such manner as permitted or required by applicable law, and you hereby consent to such transfer.
            The Company will comply with any duly issued government or court directions to disable access to the End User
            Account information, should it be found to be illegal by a competent governmental authority.
        </p>
        <p>20. <span class="HcpSubHeading">INDEMNIFICATION</span></p>
        <p class="py-2">
            20.1. End User undertakes to indemnify, defend and hold harmless the Company and its officers, directors,
            partners, owners, administrator, independent contractors, subsidiaries, licensors, suppliers, employees, agents
            and affiliates for and from any loss, claim, actions, demands, liabilities and settlements, including lawyer's
            fees, fees of third parties, etc., ("Claims"), by reason of, in any way relating to, or arising out of End
            User's violation of these terms and conditions, or any conduct of the End User or any other person operating for
            and on behalf of the End User. The End User further undertakes to indemnify & hold harmless, the Company against
            any judgment, proceedings, liability or cost resulting from or arising out of Use of the Site or
            information/data provided on the Site or Services provided by the Company, to the End User.
        </p>
        <p class="py-2">
            20.2. End User hereby undertakes to indemnify the Company for all losses, damage, costs, charges and expenses
            incurred as a result of End User's misrepresentations or fraudulent feedback that has adversely affected the
            Company its End Users, or HCPs, or has caused any direct or indirect loss to the Company.
        </p>
        <p class="py-2">
            20.3. End User further agrees to indemnify, defend and hold harmless End User's treating HCP(s) from and against
            any third-party Claims resulting from End User's lack of adherence with the advice or recommendation(s) of such
            treating HCP.
        </p>
        <p>21. <span class="HcpSubHeading">GEOGRAPHICAL RESTRICTIONS</span></p>
        <p class="py-2">
            21.1. The Company makes no representation that all End User Services and/or material described on the Site, or
            the End User Services available through the Site, are appropriate or available for use in locations in all
            states and territories within India.
        </p>
        <p>22. <span class="HcpSubHeading">LIMITATION OF LIABILITY</span></p>
        <p class="py-2">
            22.1. This paragraph shall apply to all content and functionality of the Company, Site, System, Services and End
            User Services.

        </p>
        <p class="py-2">
            22.2. Under no circumstances, including on account of any act, omission, commission or negligence, shall the
            Company including or anyone else involved in creating, producing or distributing the Services be liable for any
            direct, indirect, incidental, special or consequential damages that result from the use of or inability to use
            the Site, Services, End User Services or that results from mistakes, omissions, interruptions, deletion of
            files, errors, defects, delays in operation, or transmission or any failure of performance, whether or not
            limited to acts of God, communication failure, theft, destruction or unauthorized access to the Company records,
            programs or the Site, Services, or End User Services.
        </p>
        <p class="py-2">
            22.3. Notwithstanding the above, the End User's exclusive remedies for all damages, losses and causes of
            actions, whether in contract, including negligence or otherwise, shall not exceed the aggregate amount, which
            the Company has received from the End User for the service in question. Such limitations shall apply to the
            Company's total liability, including without limitation any liability for damages caused or allegedly caused by
            any failure of performance, error, omission, interruption, deletion, defect, delay in operation of transmission,
            communications failure, theft of destruction of or unauthorized access to, alteration of, or use of records,
            whether for breach of contract, tortuous behaviour, negligence, or under any other cause of action.
        </p>
        <p class="py-2">
            22.4. In no event shall the Company be liable for any direct damages in excess of the purchase price paid by the
            End User for the Service(s). In addition, to the maximum extent permitted by law, in no event shall the Company
            be liable for any special, punitive, indirect, incidental or consequential damages, including but not limited to
            personal injury, wrongful death, loss of use, loss of profits, interruption of service or loss of data, whether
            in any action in warranty, contract, tort (including, but not limited to negligence or fundamental breach), or
            otherwise arising out of or in any way connected with the use of, or the inability to use, this Site or any
            Service offered through this Site or any material or information contained in, accessed through, or products
            purchased from the Site, even if an authorized representative of the Company is advised of the likelihood or
            possibility of the same.
        </p>
        <p class="py-2">
            22.5. The Company shall not be liable for any breach of service or service deficiency by any HCP.
        </p>
        <p class="py-2">
            22.6. The Company shall not be liable for any damages or injury caused by any failure of performance, error,
            omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line
            failure, theft or destruction or unauthorized access to, alteration of, or use of record, in any way relating to
            or arising out of the use of the Site or End User Services.
        </p>
        <p class="py-2">
            22.7. The Company has no liability or responsibility, in case of any dispute or difference that may arise, with
            respect to the payment made by the End User or the misuse of the information and details, inserted by the End
            User at the time of making payment.
        </p>
        <p class="py-2">
            22.8. Without prejudice to the generality of the above, the Company will not be liable (directly or vicariously)
            for:
        </p>
        <p class="py-2">
            22.8.1. any wrong medication or treatment quality being given by the HCP(s), or any medical negligence on part
            of the HCP(s);
        </p>
        <p class="py-2">
            22.8.2. any misconduct or inappropriate behaviour by the HCP or the HCP's staff;
        </p>
        <p class="py-2">
            22.8.3. cancellation or rescheduling of booked appointment or any variance in the fees charged;
        </p>
        <p class="py-2">
            22.8.4. any direct or indirect, medical eventualities that might occur subsequent to using the End User Services
            of a HCP with whom the End User has booked an appointment through the Site;
        </p>
        <p class="py-2">
            22.8.5. Further, the Company shall not be liable, under any event, for any comments or feedback given by any of
            the End Users in relation to the End User Services provided by another End User. All such feedback should be
            made in accordance with applicable law. The option of End Users to give feedback remains at the Company's sole
            discretion and may be modified or withdrawn at its sole discretion. The Company may moderate such feedback at
            any time. The Company shall not be obliged to act in any manner to give effect to the content of End Users'
            feedback, such as suggestions for delisting of a particular HCP from the Site.
        </p>
        <p>23. <span class="HcpSubHeading">THIRD-PARTY ADVERTISEMENTS</span></p>
        <p class="py-2">
            23.1. The Company reserves the rights to display sponsored advertisements or third-party advertisements on the
            Site. Without prejudice to the status of other content, the Company will not be liable for the accuracy of
            information, or the claims made in the Sponsored information. The Company does not require or encourage End
            Users to visit any Sponsored information or avail any end user services from the sponsors. The Company is not
            liable for the End User services of the providers of the Sponsored information. Your dealings with, or
            participation in promotions of, advertisers, other than the Company, found on or through the Site, including
            payment and delivery of related goods or services, and any other terms, conditions, warranties or
            representations associated with such dealings, are solely between you and such advertiser. The Company shall not
            be responsible or liable for any claim, error, omission, inaccuracy in advertising material or any loss or
            damage of any sort incurred as the result of any such dealings or as the result of the presence of such
            advertisers on the Site.
        </p>
        <p class="py-2">
            23.2. Further, the Company shall not be responsible nor liable for any consequential damages arising on account
            of you relying on the contents of these advertisements.
        </p>
        <p>24. <span class="HcpSubHeading">APPLICABLE LAW AND DISPUTE SETTLEMENT</span></p>
        <p class="py-2">
            24.1. End User agrees that the terms of this Agreement and any contractual relationship arising out of the
            Agreement or use of the Site or Services, or End User Services shall be governed by and construed solely and
            exclusively in accordance with the Indian laws.
        </p>
        <p class="py-2">
            24.2. The End User hereby agrees that any action at law or in equity, arising out of or relating to these Terms
            and Conditions, including but not limited to arbitration or any issue arising out of the arbitration
            proceedings, appointment of the arbitrator, grant of injunction, which is arising from this Agreement, shall be
            filed only in the Courts at Chennai.
        </p>
        <p class="py-2">
            24.3. The End User hereby consents and submits to the exclusive jurisdiction of Courts of competent jurisdiction
            at Chennai, India which shall be to the exclusion of all other courts in India or any other country.
        </p>
        <p class="py-2">
            24.4.Any dispute, claim or controversy arising out of or relating to this Agreement, including the determination
            of the scope or applicability of this Agreement to arbitrate, or your use of the Site or the End User Services
            or information to which it gives access, shall be determined by Arbitration, before a sole arbitrator appointed
            by the Company.
        </p>
        <p class="py-2">
            24.5. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996.
        </p>
        <p class="py-2">
            24.6. The seat and venue of such arbitration shall be at Chennai.
        </p>
        <p class="py-2">
            24.7. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English
            language.
        </p>
        <p class="py-2">
            24.8. The award shall be final and binding on the parties to the dispute.
        </p>
        <p>25. <span class="HcpSubHeading">SEVERABILITY</span></p>
        <p class="py-2">
            25.1. If any or part of this Agreement is declared invalid, unlawful or unenforceable, then such part will be
            severed. The remaining parts of this Agreement will continue to be valid and enforceable to the fullest extent
            permitted by applicable laws and regulations.
        </p>
        <p>26. <span class="HcpSubHeading">WAIVER</span></p>
        <p class="py-2">
            26.1. Any waiver by the Company of any breach of any term or condition of the Agreement by the End User, shall
            not preclude the Company from enforcing any subsequent breach of that or any other term or condition and shall
            not be considered as a waiver of the subsequent breach also.
        </p>
        <p>27. <span class="HcpSubHeading">SYSTEM REQUIREMENTS</span></p>
        <p class="py-2">
            27.1. For accessing the Site and the Services offered by the Company, the End User shall be solely and
            exclusively responsible to determine the appropriateness and requirements of the system, hardware and software,
            including an appropriate multimedia mobile or any other device or computer with camera, speaker, microphone and
            scanner, etc., The Company does not warrant suitability of any system including hardware. However, the Company
            may come-up with non-mandatory advice about the System requirements, which the End User may accept or reject at
            his own discretion.
        </p>
        <p class="py-2">
            27.2. If you use these End User Services, you consent to us and our Affiliates' and licensees' transmission,
            collection, maintenance, processing and use of your location data and queries to provide and improve
            location-based products and End User Services. You may withdraw this consent at any time by turning off the
            location End User Services settings on your device. Such location Services and the use of the End User devices
            through the Internet and the telephony may involve cost for which the End User shall directly connect with the
            Internet or Telecom Service Provider.
        </p>
        <p>28. <span class="HcpSubHeading">ASSIGNMENT</span></p>
        <p class="py-2">
            28.1. You hereby agree that the Company may assign this Agreement along with the Company, Site and Services at
            any time at its sole discretion, without the requirement of any consent from the End User and the End User also
            waives the right of any such requirement of consent, to any parent, subsidiary or affiliated company, or as part
            of the sale to, merger with, or other transfer or license of any kind, of the Company to another entity(s); and
            any such assignee or transferee or licensee shall automatically step in to the shoes of the Company for the
            purpose of this Agreement.
        </p>
        <p class="py-2">
            28.2. The End User shall not be entitled to assign, transfer or sublicense these End User Terms of Use to anyone
            else and any attempt to do so in violation of this Agreement, and the same shall be null and void.
        </p>
        <p class="py-2">
            END USER UNDERSTANDS AND ACKNOWLEDGES THAT BY CHECKING THE "AGREE/ I AGREE" CHECKBOX ON THE SITE, AT THE TIME OF
            REGISTRATION, FOR THIS AGREEMENT AND/OR ANY OTHER FORM(S) OR AGREEMENT PRESENTED TO YOU ON THE SITE, OR BY USING
            ANY SERVICES, YOU ARE AGREEING TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, AND THAT SUCH ACTION /
            CONFIRMATION CONSTITUTES A LEGAL SIGNATURE RESULTING IN A BINDING CONTRACT BETWEEN YOU AND THE COMPANY.
        </p>
    </div>
</template>
<script>
export default ({
  components: {
  },
  data() {
    return {}
  }
})
</script>
<style>
.usertermsCondtionMainDiv {
    text-align: justify;
}

.userTermConditionHeading {
    font-size: 22px;
    color: black;
    font-weight: 550;
    margin-top: 5rem;
}

.UserSubHeading {
    font-size: 19px;
    color: black;
    font-weight: 550;
}
@media screen and (max-width:991px) {
    .userTermConditionHeading {
        margin-top: 2rem;
    }
}
</style>